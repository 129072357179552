export default {
  home: {
    // 货到付款
    cashOnDelivery: 'الدفع عند الإستلام',
    // 免费送货
    freeDelivery: 'الشحن مجاني',
    // 真正的保证
    theRealGuarantee: 'ضمان حقيقي',
    // 产品描述
    productDescription: 'وصف المنتج',
    // 商品详情
    productDetails: 'تفاصيل الطلب',
    // 购买按钮
    purchaseButton: 'اشتري الآن'
  },
  placeOrder: {
    btnA: 'قطعة مقابل ',
    btnB: 'قطعة مقابل',
    // 数量
    quantity: 'كمية',
    // 发货信息
    shippingInfo: 'معلومات الشحن',
    // 全名
    fullName: 'الاسم بالكامل',
    // 请输入您的全名
    pleaseEnterFullName: '*الاسم بالكامل',
    // 电话号码
    phoneNumber: 'الرجاء إدخال رقم هاتفك هنا',
    // 请输入您的电话号码
    pleaseEnterPhone: '*الرجاء إدخال رقم هاتفك هنا',
    // 完整送货地址
    fullShippingAddress: 'البيت والشارع',
    // 尽可能详细的送货地址
    pleaseEnterAddress: 'على سبيل المثال منزل 1101، 10 ش',
    // 邮件
    email: 'البريد الإلكتروني',
    // 留下您的电子邮件以获取通知
    pleaseEnterEmail: 'اترك بريدك الإلكتروني للحصول على الإشعارات',
    // 备注
    Note: 'اكتب ملاحظة',
    // 如果有的话，写一张便条，比如交货时间
    pleaseEnterNote: 'اكتب ملاحظة إذا كان لديك',
    // 总的
    Total: 'المجموع',
    // 付款
    payment: 'الدفع',
    // 沙币
    SAR: 'ر.س',
    // 是
    yes: 'نعم',
    // 稍后
    later: 'فيما بعد',
    // 收货人
    consignee: 'المرسل اليه',
    // 订单号
    orderNumber: 'رقم الطلب',
    // 下单成功提示 您购买的产品已经下达成功,请点击YES并联系商家
    successB: 'المنتجات التي قمت بشرائها قد صدر بنجاح ،',
    successC: 'من فضلك اضغط نعم',
    successD: 'whatsapp واتصل رجال الأعمال',
    // 请选择商品规格
    pleaseChoose: 'الرجاء اختيار مواصفات المنتج'
  },
  productList: {
    // 加载中...
    loading: 'جاري التحميل...',
    //请求失败，请稍后重试！
    fail: 'فشل الطلب، يرجى المحاولة مرة أخرى لاحقًا!',
    // 下单成功！
    success: 'تم الطلب بنجاح!',
    // 已经是最后一页
    lastPage: 'إنها الصفحة الأخيرة ',
    // 货到付款，免费送货
    promptLanguage: 'الدفع عند التسليم ، والشحن مجانا'
  }
}
