import http from '@/libs/http'
// 获取商品接口

export const getTableData = (url, data) => {
  return http.get(url, data)
}

export const getBuriedPoint = (url, data) => {
  return http.post(url, data)
}
