import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import { createApp } from 'vue'
import { Lazyload } from 'vant'
import { Notify } from 'vant'
import { Toast } from 'vant'
import axios from 'axios'

// 引入插件和语言包
import VueI18n from 'vue-i18n'
import sa from '@/i18n/langs/sa'
import en from '@/i18n/langs/en'
Vue.use(VueI18n)

// 自动设置多语言 默认选择浏览器系统语言
const navLang = navigator.language //判断当前浏览器使用的语言
console.log(navLang)
const localLang = navLang === 'sa' || navLang === 'en-US' ? navLang : false
let lang = localStorage.getItem('language') || localLang || 'sa' //如果有缓存，取缓存的语言
const i18n = new VueI18n({
  locale: lang,
  messages: {
    sa: sa, // 沙特语言包
    en: en // 英文语言包
  },
  silentTranslationWarn: true
})
// const app = Vue()
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true
})
Vue.use(Notify)
Vue.use(Vant)
Vue.use(Toast)

// Vue.use(vant.Lazyload)
axios.defaults.baseURL = '/api' //设置基址
axios.defaults.headers.post['content-Type'] = 'application/json'
Vue.prototype.axios = axios
Vue.config.productionTip = false
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth >= 750) {
    htmlWidth = 750
  }
  // if (htmlWidth <= 320) {
  //   htmlWidth = 320
  // }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`
  // 设置body最大宽度 margin自适应
  document.getElementsByTagName('body')[0].style.maxWidth = `7.5rem`
  document.getElementsByTagName('body')[0].style.margin = `auto`
}
window.onresize = setHtmlFontSize
setHtmlFontSize()
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  i18n,
  axios,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
