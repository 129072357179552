import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index'

Vue.use(VueRouter)

const routes = [
  {
    // 商品主页
    path: '/',
    name: 'home',
    component: Home
  },
  {
    // 下单页
    path: '/placeOrder',
    name: 'placeOrder',
    hidden: true,
    component: () => import('@/views/placeOrder/index.vue')
  },
  {
    //  商品页 productList
    path: '/productList',
    name: 'productList',
    hidden: true,
    component: () => import('@/views/productList/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
