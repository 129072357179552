<template>
  <div id="app" style="">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() {
    let hostname = window.location.hostname
    hostname = hostname.replace('www.', '')
    document.title = hostname
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
body {
  max-width: 7.5rem;
  margin: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
