<template>
  <div class="home">
    <!-- 头部 -->
    <div class="head-box">
      <div style="flex: 1" @click="showLeft = !showLeft"><van-icon name="wap-nav" /></div>
      <div class="content-boxA" style="flex: 1">
        <img v-if="hostname == 'arababy.shop'" src="@/assets/img/baby.jpg" alt="" />{{ hostname }}
      </div>
      <van-popover style="flex: 1" v-model="showPopover">
        <van-grid square clickable :border="false" column-num="3" style="width: 2rem">
          <div class="language-box">
            <div v-for="(item, i) in languageList" :key="i" class="selectLanguage" @click="onLanguage(item)">
              <span>{{ item.label }}</span>
            </div>
          </div>
        </van-grid>
        <template #reference>
          <div @click="showPopover = true" class="head-right">
            <img :src="languageData.src" />
            <span>{{ languageData.label }}</span>
          </div>
        </template>
      </van-popover>
    </div>
    <!-- 轮播图    -->
    <van-swipe style="min-height: 5rem" class="my-swipe" :touchable="true" :autoplay="3000" lazy-render>
      <van-swipe-item v-for="(item, index) in imgList" :key="index">
        <img :src="item" />
      </van-swipe-item>
    </van-swipe>
    <!-- 价格详情 -->
    <div class="price-details">
      <div v-if="languageData.name == 'en'">
        <span>{{ $t('placeOrder.btnA') }}</span>
        <span> </span>
        <span>{{ discount_priceA }}</span>
        <span> {{ $t('placeOrder.SAR') }}</span>
      </div>
      <div v-else>
        <span>{{ $t('placeOrder.SAR') }}</span>
        <span></span>
        <span>{{ discount_priceA }}</span>
        <span> {{ $t('placeOrder.btnA') }}</span>
        <span> 1</span>
      </div>

      <div v-if="languageData.name == 'en' && discount_priceB">
        <span>{{ $t('placeOrder.btnB') }}</span>
        <span> </span>
        <span>{{ discount_priceB }}</span>
        <span> {{ $t('placeOrder.SAR') }}</span>
      </div>
      <div v-else-if="languageData.name == 'sa' && discount_priceB">
        <span>{{ $t('placeOrder.SAR') }}</span>
        <span>{{ discount_priceB }}</span>
        <span> {{ $t('placeOrder.btnB') }}</span>
        <span>2</span>
      </div>
    </div>
    <!-- 商品详情 -->
    <div class="productDetails">
      <div class="head">
        <!-- 货到付款 -->
        <span>{{ $t('home.cashOnDelivery') }}</span>
        <!-- 免费送货 -->
        <span>{{ $t('home.freeDelivery') }}</span>
        <!-- 真正的保证 -->
        <span>{{ $t('home.theRealGuarantee') }}</span>
      </div>
      <div class="SKU">
        <div>{{ ProductInformation.title }}</div>
        <div>SKU{{ ProductInformation.info.length > 0 ? ProductInformation.info[0].sku : '' }}</div>
      </div>
      <div style="height: 0.1rem; background: rgb(245, 245, 245)"></div>
      <div class="bottom-text">Product Description</div>
      <div class="img-List" v-html="ProductInformation.content"></div>
      <div style="width: 7.5rem">
        <img
          v-if="languageData.name == 'sa'"
          style="width: 100%"
          src="https://d1c83bvmphpuwx.cloudfront.net/s1/img/arOrderwith.jpg"
          alt=""
        />
        <img
          v-if="languageData.name == 'en'"
          style="width: 100%"
          src="https://d1c83bvmphpuwx.cloudfront.net/s1/img/enOrderwith.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="order-details">
      <!-- 商品详情 -->
      <div class="titel">{{ $t('home.productDetails') }}</div>
      <div v-if="languageData.name == 'en'" class="tab-box">
        <table class="data-table">
          <tbody>
            <tr class="first odd">
              <th style="width: 0.9rem">Guarantee</th>
              <td>
                <p></p>
                <p>
                  Free shipping and cash on delivery.<br />
                  After receiving the product you can return it within 7 days<br />
                  If you have any questions after receiving the product please contact the live chat or e-mail to
                  <a href="aramoonstore@gmail.com" style="color: rgb(248, 119, 14)">aramoonstore@gmail.com</a>&nbsp;Please inform your name,
                  contact information, and order number.
                  <br />
                  Our staff will reply to you as soon as possible and you can use our WhatsApp Number:
                  <a href="https://api.whatsapp.com/send?phone=14504251801&amp;text=I%20want%20TO%20consult%20http://www.aranoon.com"
                    >+546326499</a
                  >
                  to contact us, We wish you a happy shopping!
                </p>
                <p></p>
              </td>
            </tr>
            <tr class="first odd">
              <th>Contact Us</th>
              <td>
                <p></p>
                <p>
                  Live Chat <br />
                  <a href="aramoonstore@gmail.com">Email：aramoonstore@gmail.com</a> <br />
                  If you have any questions, please call WhatsApp Number:
                  <a href="https://api.whatsapp.com/send?phone=14504251801&amp;text=I%20want%20TO%20consult%20http://www.aranoon.com"
                    >+546326499</a
                  >
                  or consult online customer service, thank you for your cooperation.
                </p>
                <p></p>
              </td>
            </tr>
            <tr class="first odd">
              <th><img src="" style="width: 60px; height: auto" /></th>
              <td>
                <p>
                  Our Website, in-depth around the world, strict control of all Product origin, technology, materials, and give you the best
                  quality products.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <table class="data-table">
          <tbody>
            <tr class="first odd">
              <td>
                <p>
                  الشحن مجانا والدفع عند التسليم.<br />
                  بعد استلام المنتج ، يمكنك إعادته خلال 7 أيام<br />
                  إذا كانت لديك أي أسئلة بعد استلام المنتج ، فيرجى الاتصال بالدردشة المباشرة أو البريد الإلكتروني إلى
                  <a href="aramoonstore@gmail.com" style="color: rgb(248, 119, 14)">aramoonstore@gmail.com</a>. يرجى تزويدنا بإسمك ومعلومات
                  الاتصال ورقم الطلب. <br />
                  سيقوم موظفونا بالرد عليك في أقرب وقت ممكن ويمكنك استخدام رقم الواتساب:
                  <a href="https://api.whatsapp.com/send?phone=547575915&amp;text=I%20want%20TO%20consult%20http://www.aranoon.com">
                    547575915+</a
                  >للاتصال بنا ، نتمنى لك تسوق سعيد!
                </p>
              </td>
              <th style="width: 0.9rem">الضمان</th>
            </tr>
            <tr class="first odd">
              <td>
                <p>
                  دردشة مباشرة
                  <br />
                  البريد الإلكتروني：<a href="aramoonstore@gmail.com" style="color: rgb(248, 119, 14)">aramoonstore@gmail.com</a><br />
                  إذا كان لديك أي أسئلة ، يرجى الاتصال على رقم الواتساب :
                  <a href="https://api.whatsapp.com/send?phone=547575915&amp;text=I%20want%20TO%20consult%20http://www.aranoon.com">
                    547575915+</a
                  >
                  أو تواصل مع خدمة العملاء عبر الإنترنت ، شكرًا لك على تعاونك.
                </p>
              </td>
              <th>اتصل بنا</th>
            </tr>
            <tr class="first odd">
              <td>
                <p>
                  موقعنا الإلكتروني ، المتعمق حول العالم ، يتحكم بشكل صارم في منشأ المنتج ، التكنولوجيا ، المواد ، ويعطيك أفضل المنتجات
                  عالية الجودة.
                </p>
              </td>
              <th><img src="" style="width: 60px; height: auto" /></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AboutView />
    <!-- 底部导航栏 -->
    <div class="bottom-fixed-navBar">
      <!-- <van-button class="box-radius">
        <van-icon name="description-o" />
      </van-button> -->
      <!-- <van-button type="default" class="box-radius">
        <van-icon name="phone-circle-o" @click="onWhatsapp" />
      </van-button> -->
      <!-- 购买按钮 -->
      <van-button @click.stop="buyNow" class="button-box" round type="default" color="red" icon="shopping-cart-o">
        {{ Cust ? 'اطلب عبر الواتس اب' : $t('home.purchaseButton') }}</van-button
      >
    </div>
    <van-popup v-model="showLeft" position="left" :style="{ width: '40%', height: '100%' }">
      <!-- <van-button class="button-box" type="default"></van-button> -->
      <div @click="jumpTo" class="button-box"><van-icon name="home-o" />product list</div>
    </van-popup>
    <!-- 圆角弹窗（居中） -->
    <van-popup v-model="showA" round style="width: 80%">
      <div class="content-box">
        <div>انقر فوق نعم</div>
        <div><span>Whatsapp</span><span>اتصل تاجر</span></div>

        <!-- <div v-if="languageData.name">{{ $t('placeOrder.successD') }}</div> -->
      </div>
      <div class="btn-box">
        <!-- 稍后 -->
        <van-button @click="showA = false" type="default">{{ $t('placeOrder.later') }}</van-button>
        <!-- 是 -->
        <van-button @click="onYes" type="primary" color="#2f85da">{{ $t('placeOrder.yes') }}</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import AboutView from '@/views/AboutView.vue'
import { Notify } from 'vant'
import { getTableData, getBuriedPoint } from './api/index.js'
export default {
  components: { AboutView },
  data() {
    return {
      ProductInformation: {
        info: [{ discount_price: 0, sale_price: 0 }]
      },
      Cust: '', // 判断是否直接跳whatsapp
      // 轮播图数据
      imgList: [],
      showLeft: false,
      // 语言气泡框
      showPopover: false,
      showA: false,
      hostname: '',
      // 语言选择框数据
      languageList: [
        {
          label: 'العربية',
          name: 'sa'
        },
        {
          label: 'English',
          name: 'en'
        }
      ],
      // 选中语言值
      languageData: {
        label: 'العربية',
        name: 'sa'
      },
      discount_priceA: '',
      discount_priceB: ''
    }
  },
  created() {
    let hostname = window.location.hostname
    hostname = hostname.replace('www.', '')
    this.hostname = hostname
    console.log(this.$route.query.Cust)
    if (this.$route.query.Cust == 'Cust') {
      this.Cust = this.$route.query.Cust
      localStorage.setItem('Cust', this.$route.query.Cust)
    } else if (localStorage.getItem('Cust') == 'Cust') {
      this.Cust = localStorage.getItem('Cust')
    }
    if (!this.$route.params.id && !this.$route.query.id && !localStorage.getItem('id')) {
      this.jumpTo()
    } else {
      if (this.$route.params.id || this.$route.query.id) {
        localStorage.setItem('id', this.$route.params.id ? this.$route.params.id : this.$route.query.id)
      }
      if (this.$route.query.id) {
        localStorage.setItem('url', window.location.href)
        if (this.$route.query.sc) localStorage.setItem('sc', this.$route.query.sc)
      }
      this.onLanguage(this.languageData)
      console.log(this.$route.params.id)
      console.log(this.$route.query.id)
      localStorage.getItem('id')
      this.getCommodity(localStorage.getItem('id'))
    }
  },
  mounted() {},
  methods: {
    // 提交订单成功 跳转聊天
    onYes() {
      this.showA = false
      if (this.Cust == 'Cust') {
        let url = localStorage.getItem('url')
        if (url.indexOf('&Cust=Cust')) {
          url = url.replace('&Cust=Cust', '')
        }
        if (url.indexOf('&utm_source: fb')) {
          url = url.replace('&utm_source: fb', '')
        }
        let data = {
          action: '联系客服下单',
          url: localStorage.getItem('url') ? localStorage.getItem('url') : ''
        }
        getBuriedPoint('/api/order/order/record', data)
        window.location.href = `https://api.whatsapp.com/send?phone=547575915` + '&text=link:%20%20' + url + '%20'
        return
      }
    },
    // 链接onWhatsapp
    onWhatsapp() {
      let url = localStorage.getItem('url')
      window.location.href = `https://api.whatsapp.com/send?phone=547575915` + '&text=link:%20%20' + url + '%20'
    },
    // 向上取整
    onToFixed(val) {
      return val.toFixed()
    },
    // 语言选择框点击事件
    onLanguage(item) {
      this.languageData = item
      this.$i18n.locale = item.name
      localStorage.setItem('language', item.name)
      this.showPopover = false
    },
    // 获取商品
    getCommodity(id) {
      getTableData('/api/goods/goods/get', { hash_id: id })
        .then((res) => {
          console.log(res)
          if (res.code == 1) {
            res.data.info.forEach((element, index) => {
              if (index == 0) {
                if (!element.discount_price) {
                  this.discount_priceA = element.sale_price
                  res.data.discount_priceA = element.sale_price
                } else {
                  this.discount_priceA = element.discount_price.toFixed()
                  res.data.discount_priceA = element.discount_price.toFixed()
                }
              }
              if (index == 1) {
                if (!element.discount_price) {
                  this.discount_priceB = element.sale_price
                  res.data.discount_priceB = element.sale_price
                } else {
                  this.discount_priceB = element.discount_price.toFixed()
                  res.data.discount_priceB = element.discount_price.toFixed()
                }
              }
            })
            localStorage.setItem('data', JSON.stringify(res.data))
            this.ProductInformation = res.data
            console.log(res.data.images)
            this.imgList = res.data.images
          } else {
            Notify({ type: 'danger', message: this.$t('productList.fail') })
            let than = this
            setTimeout(() => {
              than.jumpTo()
            }, 3000)
          }
        })
        .catch((err) => {
          Notify({ type: 'danger', message: this.$t('productList.fail') })
        })
    },
    // 点击立即购买
    buyNow() {
      if (!this.ProductInformation.goods_id) {
        return
      }
      if (this.Cust == 'Cust') {
        this.showA = true
        return
      }
      console.log(this.$router)
      this.$router.push({
        path: '/placeOrder'
      })
    },
    // 跳转商品列表
    jumpTo() {
      this.$router.push({
        path: '/productList'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.language-box {
  width: 100%;
  padding: 0.2rem;
  .selectLanguage {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.3rem;
    margin-bottom: 0.1rem;
    padding: 0.1rem 0;
    border-bottom: 1px solid #f5f5f5;
    img {
      height: 0.35rem;
    }
    span {
      margin-left: 0.1rem;
    }
  }
}
.van-popup {
  .button-box {
    padding: 0.3rem 0.2rem;
    font-size: 0.3rem;
    border-bottom: 1px solid #ccc;
    text-align: left;
  }
}
.van-button {
  height: auto;
}
.home {
  width: 7.5rem;
}
.head-box {
  position: fixed;
  top: 0;
  z-index: 100;
  box-align: border-box;
  width: 7.2rem;
  display: flex;
  flex: 3;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.15rem;
  font-size: 0.3rem;
  font-weight: 700;
  background: #fff;
  border-bottom: 0.03rem solid #e7e7e7;
  border-top: 0.03rem solid #e7e7e7;
  line-height: 1rem;
  height: 1rem;
  margin: 0;

  div {
    font-size: 0.4rem;
    text-align: left;
  }
  .content-boxA {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.2rem;
    img {
      width: 0.8rem;
      border-radius: 50%;
      margin-right: 0.1rem;
    }
  }
  .head-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 0.5rem;
    line-height: 0.5rem;
    padding: 0 0.15rem;
    border-radius: 0.3rem;
    border: 1px solid #e1e1e1;
    background: #f5f5f5;
    font-size: 0.28rem;
    // margin-right: 0.2rem;
    float: right;
    margin-top: 0.15rem;
    img {
      height: 0.35rem;
      margin-right: 0.1rem;
      margin-left: 0.03rem;
    }
    span {
      font-size: 0.28rem;
      font-weight: 700;
    }
  }
}
.my-swipe {
  width: 100%;
  margin-top: 1.05rem;
  img {
    width: 100%;
  }
}
.price-details {
  padding: 0.15rem;
  border-top: 1px dashed #ced4da;
  overflow: hidden;
  background: linear-gradient(to right, #ef3390, #ef3647);
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.34rem;
    font-weight: 700;
    color: #fff;
    span:nth-child(2) {
      margin: 0 0.2rem;
    }
    span:nth-child(3) {
      margin: 0 0.2rem 0 0;
    }
  }
}
.productDetails {
  padding: 0.3rem 0;

  .head {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.28rem;
    color: #333;
    padding: 0 0.3rem;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.1rem 0.1rem;
      padding-left: 0.45rem;
    }
  }
  .SKU {
    border-top: 1px dashed #ced4da;
    padding: 0.3rem 0.5rem;
    overflow: hidden;
    text-align: left;
    div:nth-child(1) {
      color: #000;
      font-size: 0.3rem;
      font-weight: 700;
      white-space: pre-wrap;
    }
    div:nth-child(2) {
      font-size: 0.28rem;
      color: rgb(129, 129, 129);
    }
  }
  .bottom-text {
    text-align: left;
    padding: 0.2rem 0 0.2rem 0.5rem;

    font-size: 0.3rem;
    color: #000;
  }
  /deep/ .img-List {
    margin-top: 0.5rem;
    font-size: 0.3rem;
    text-align: left;
    padding: 0 0.2rem;
    img {
      width: 100%;
    }
    p {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
.order-details {
  margin-bottom: 1.21rem;
  .titel {
    text-align: left;
    background: #fff;
    font-weight: 700;
    color: #000;
    font-size: 0.28rem;
    display: block;
    margin-left: 0.15rem;
    margin-bottom: 0.1rem;
  }
  .tab-box {
    tr {
      display: table-row;
      vertical-align: inherit;
      unicode-bidi: isolate;
      border-color: inherit;
      td {
        text-align: left;
      }
      p {
        word-wrap: break-word;
        overflow: hidden;
        display: block;
        text-align: left;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        margin: 0;
      }
    }
    // display: flex;
    // font-size: 0.28rem;
    // color: #0f1111;
    // border-top: 1px solid rgb(129, 129, 129);
    // div:nth-child(1) {
    //   display: flex;
    //   align-items: center;
    //   border-top: 0.01rem solid #e7e7e7;
    //   border-bottom: 0.01rem solid #e7e7e7;
    //   font-weight: 400;
    //   padding: 0.05rem;
    //   background: none repeat scroll 0% 0% #f3f3f3;
    //   text-transform: uppercase;
    //   font-family: arial;
    //   white-space: nowrap;
    // }
    // div:nth-child(2) {
    //   text-align: left;
    //   padding: 0.2rem;
    // }
  }
}
.bottom-fixed-navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.3rem;
  width: 7.5rem;
  height: 1.2rem;
  position: fixed;
  bottom: 0;
  z-index: 9;
  background-color: white;
  margin: auto;
  .box-radius {
    border-radius: 0.1rem;
    color: red;
    font-size: 0.5rem;
  }
  .button-box {
    width: 100%;
    padding: 0.2rem;
    font-size: 0.2rem;
    font-weight: 600;
    -webkit-animation: bynowRotate 5s linear infinite;
    animation: bynowRotate 5s linear infinite;
    animation-delay: 0s;
    animation-delay: 10s;
    -webkit-animation-delay: 10s;
    transition-duration: 0ms;
  }
  @keyframes bynowRotate {
    0% {
      transform: rotate(-5deg);
    }
    10% {
      transform: rotate(5deg);
    }
    30% {
      // left: 200px;
      transform: rotate(-5deg);
    }
    40% {
      transform: rotate(5deg);
    }
    // 40% {
    //   // left: 200px;
    //   transform: rotate(-15deg);
    // }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
.data-table {
  width: 100%;
  font-size: 0.28rem;
  color: #0f1111;
  margin-top: 0;
  margin-bottom: 0.45rem;
  tbody {
    width: 100%;
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
    td {
      text-align: left;
    }
    p {
      word-wrap: break-word;
      overflow: hidden;
      display: block;
      text-align: right;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      unicode-bidi: isolate;
      margin: 0;
    }
  }
}
.content-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.3rem;
  margin: 0.4rem;
  div:nth-child(2) {
    height: 0.4rem;
  }
}
/deep/.btn-box {
  display: flex;
  justify-content: space-between;
  margin: 0.4rem;
  .van-button {
    padding: 0.1rem 0.2rem;
  }
}
</style>
